<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import xss from 'xss'
import stripTags, { basicTags } from '@/utils/strip_tags.js'
import CopyToClipboardButton from '@/components/Buttons/CopyToClipboardButton.vue'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import OpeningHours from '@/components/Misc/OpeningHours.vue'
import EstablishmentStatus from '@/pages/establishment/components/EstablishmentStatus.vue'
import { DistributionType, type Establishment } from '@/http/models/Establishment'
import InfoTabStaticMap from "@/pages/establishment/components/Modals/InfoModal/InfoTab/InfoTabStaticMap.vue"

const props = defineProps<{
  establishment: Establishment
}>()

const { t } = useI18n()
const store = useStore()

const doesDeliveries = computed(() =>
    props.establishment.distributionTypes.includes(DistributionType.Delivery),
)

const doesTakeAways = computed(() =>
    props.establishment.distributionTypes.includes(DistributionType.TakeAway),
)

const deliveryHours = computed(
    () => props.establishment.openingHours[DistributionType.Delivery],
)
const takeAwayHours = computed(
    () => props.establishment.openingHours[DistributionType.TakeAway],
)

const onlyOneDistributionType = computed(() => [ doesDeliveries.value, doesTakeAways ].filter(bool => bool).length === 1)

const address = computed(() => `${props.establishment.location.street} ${props.establishment.location.houseNumber || ''}, ${props.establishment.location.zipcode} ${props.establishment.location.city}`)

const center = computed(() => {
  const { lat, lng } = props.establishment.location

  return {
    lat,
    lng,
  }
})
</script>

<template>
  <div class="establishment-contact" data-test-id="establishment-modal-info">
    <InfoTabStaticMap class="establishment-contact__map"
                      :location="`${establishment.location.coordinates.lat},${establishment.location.coordinates.lng}`"
    />

    <Row class="establishment-contact__content">
      <Column class="col-sm-12 establishment-contact__column">
        <div class="establishment-contact__branding">
          <h2 class="establishment-contact__name">
            {{ establishment.title }}
          </h2>
          <span class="establishment-contact__categories">{{ establishment.categoriesString }}</span>
        </div>

        <p class="establishment-contact__description">
          <!-- eslint-disable vue/no-v-html -->
          <span v-for="(line, index) in props.establishment.description"
                :key="index"
                v-html="xss(stripTags(line, basicTags)) || '&nbsp;'"/>
          <!-- eslint-enable vue/no-v-html -->
        </p>

        <div class="establishment-contact__contact-copy-buttons">
          <CopyToClipboardButton data-test-id="establishment-address-copy-button"
                                 icon="pin"
                                 v-if="address">
            {{ address }}
          </CopyToClipboardButton>
          <CopyToClipboardButton data-test-id="establishment-phone-copy-button"
                                 icon="phone-line"
                                 v-if="establishment.phone">
            {{ establishment.phone }}
          </CopyToClipboardButton>
        </div>
      </Column>
    </Row>

    <Row class="establishment-contact__content">
      <Column data-test-id="establishment-delivery-opening-hours"
              :class="[
                'establishment-contact__column',
                {
                  'col-lg-6': !onlyOneDistributionType,
                },
              ]"
              v-if="doesDeliveries">
        <h3 class="establishment-contact__h3">
          {{ t('establishment_contact_info.delivery_hours') }}
          <EstablishmentStatus :distribution-type="DistributionType.Delivery"
                               small
                               display-closing-hours
                               :establishment="establishment"/>
        </h3>
        <OpeningHours :opening-hours="deliveryHours"/>
      </Column>
      <Column data-test-id="establishment-take-away-opening-hours"
              :class="[
                'establishment-contact__column',
                {
                  'col-lg-6': !onlyOneDistributionType,
                },
              ]"
              v-if="doesTakeAways">
        <h3 class="establishment-contact__h3">
          {{ t('establishment_contact_info.takeaway_hours') }}
          <EstablishmentStatus :distribution-type="DistributionType.TakeAway"
                               small
                               display-closing-hours
                               :establishment="establishment"/>
        </h3>
        <OpeningHours :opening-hours="takeAwayHours"/>
      </Column>
    </Row>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/mixins/breakpoints-up.scss";

.establishment-contact {
  &__content {
    padding: 0 2rem 2rem 2rem;

    @include lg-up {
      padding: 0 3rem 3rem 3rem;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap:     0.25rem;
    padding: 0 1.25rem;
  }

  &__map {
    height: 13rem !important; // TODO: remove the !important after vite css chunk problem is solved
    width: calc(100%) !important; // TODO: remove the !important after vite css chunk problem is solved
    margin: 0 0 2rem 0;
  }

  &__name {
    font-size:   1.625rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top:  0.5rem;
  }

  &__description {
    display: block;
    margin-top: 0.25rem;
  }

  &__categories {
    font-size: 1rem;
    margin-top: 0.25rem;
    display:   block;
  }

  &__contact-copy-buttons {
    display:    flex;
    flex-direction: column;
    gap:        0.5rem;
    margin-top: 1rem;
  }

  &__h3 {
    display: flex;
    justify-content: space-between;

    &:deep(.establishment-status) {
      font-size: 1rem;
    }
  }

  &__link {
    color: var(--color-brand-primary);
  }

  &__delivery-costs {
    &__line {
      color:   var(--color-neutral-primary-80);
      display: flex;
      justify-content: space-between;
    }
  }

  &:deep(.opening-hours__line) {
    font-size: 1rem;
    line-height: 175%;
  }
}
</style>
