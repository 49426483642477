<script setup lang="ts">
import { computed, onActivated, onServerPrefetch } from 'vue'
import { useStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import Reviews from '@/components/Reviews/Reviews.vue'
import ReviewsList from '@/pages/establishment/components/Modals/InfoModal/ReviewsTab/ReviewsList/ReviewsList.vue'
import type { Establishment } from '@/http/models/Establishment'
import { useEstablishmentHttp } from '@/http/establishmentHttp'

type Props = {
  establishment: Establishment
}

const props = defineProps<Props>()

const store = useStore()
const route = useRoute()
const { t } = useI18n()

const establishmentReviews = computed<object[] | null>(() => store.getters['establishment/reviews'])

const { reviews } = useEstablishmentHttp()

onServerPrefetch(async () => {
  await reviews(
    props.establishment.slug,
    (route.params.country as string) === '' ? store.state.session.country : route.params.country as string,
    (route.params.city as string)
  )
})

onActivated(async () => {
  await reviews(
    props.establishment.slug,
    (route.params.country as string) === '' ? store.state.session.country : route.params.country as string,
    (route.params.city as string)
  )
})
</script>

<template>
  <div class="establishment-reviews"
    data-test-id="establishment-modal-reviews"
  >
    <div class="establishment-reviews__header"
    >
      <div class="establishment-reviews__header-bg"
        :style="{
          backgroundImage: `url(${establishment.cover})`
        }"
      />
      <div class="establishment-reviews__header-content">
        <h2 class="establishment-reviews__establishment-name">
          {{ establishment.title }}
        </h2>

        <div>
          {{ establishment.categoriesString }}
        </div>

        <div class="establishment-reviews__average-rating">
          <Reviews :rating="establishment.reviews.average"
            white
          />
          <div class="establishment-reviews__average-rating-score">
            {{ establishment.reviews.average }}
            ({{
              t('reviews.based-on.short', {
                amount: establishment.reviews.amount,
              })
            }})
          </div>
        </div>
      </div>
    </div>

    <div class="establishment-reviews__content">
      <ReviewsList :reviews="establishmentReviews" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.establishment-reviews {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
    height: 13rem;
    width: 100%;
    color: white;
  }

  &__header-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.75;
      background-color: var(--color-neutral-primary-80);
    }
  }

  &__header-content {
    z-index: 2;
    position: relative;
    height: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__establishment-name {
    font-size: 1.625rem;
    font-weight: 700;
    margin: auto 0 0 0;
  }

  &__content {
    min-height: 5rem;
    padding: 1.5rem;

    @include lg-up {
      padding: 3rem;
    }
  }

  &__average-rating  {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}
</style>
