import {vueSSR} from 'vite-plugin-vue-ssr'
import {createGtm, useGtm} from '@gtm-support/vue-gtm'
import {createMemoryHistory, createRouter, createWebHistory, type RouteRecordRaw} from 'vue-router'
import {Capacitor} from '@capacitor/core'
import {Device} from '@capacitor/device'
import App from '@/App.vue'
import {useI18n} from '@/i18n'
import {mapRoutesWithLocale, routes as _routes} from '@/routes'
import {createStore, key} from '@/stores'
import {plugins} from '@/plugins'
import {createSentry} from '@/plugins/sentry'
import {determineCountryAndLocaleFromUrl} from '@/internal/determineCountryAndLocaleFromUrl'
import {useGoogleMaps} from './composables/googleMaps'
import {capacitor} from '@/capacitor'
import {createHttp} from '@/composables/useHttp'
import {createTracking} from '@/composables/useTracking'

export default vueSSR(App, {}, async ({app, state, request}) => {
  const store = createStore()
  app.use(store, key)

  if (!__CAPACITOR__) {
    if (import.meta.env.SSR) {
      state.value = store.state

      if (request.cookies.token !== undefined) {
        await store.dispatch('auth/setToken', JSON.parse(Buffer.from(request.cookies.token, 'base64').toString()))
      }

      if (request.cookies.location !== undefined) {
        // note: this breaks the German eszett for example
        // await store.dispatch('session/storeCurrentLocation', JSON.parse(decodeURIComponent(Buffer.from(request.cookies.location, 'base64').toString())))

        let location = null

        try {
          location = JSON.parse(Buffer.from(request.cookies.location, 'base64').toString())
        } catch (error) {
          // do nothing
        } finally {
          await store.dispatch('session/storeCurrentLocation', location)
        }
      }

      if (request.cookies.distribution !== undefined) {
        await store.dispatch('session/storeDistributionType', request.cookies.distribution)
      }
    } else {
      // into the VueX state
      const cart = {
        ...store.state.cart,
        establishment: state.value.cart.establishment,
      }

      const session = {
        ...store.state.session,
        distributionType: state.value.session.distributionType,
        currentLocation: state.value.session.currentLocation,
        locale: state.value.session.locale,
        country: state.value.session.country,
        onLocationIdentifier: state.value.session.onLocationIdentifier,
        onLocationDirect: state.value.session.onLocationDirect,
        // temporaryLocation: state.value.session.temporaryLocation,
      }

      store.replaceState({
        ...state.value,
        // instead replacing the empty SSR initial state
        // the actual state from the localStorage is used
        session,
        user: store.state.user,
        cart,
      })
    }
  }

  if (import.meta.env.SSR) {
    const {country, locale} = determineCountryAndLocaleFromUrl(request.hostname ?? 'localhost', request.originalUrl)

    await Promise.all([
      store.dispatch('session/storeCurrentLocale', locale),
      store.dispatch('session/storeCurrentCountry', country),
    ])
  } else {
    if (__CAPACITOR__) {
      const {value} = await Device.getLanguageCode()

      // TODO: get country from device somehow

      await Promise.all([
        store.dispatch('session/storeCurrentLocale', value),
        store.dispatch('session/storeCurrentCountry', 'nl'),
      ])
    }
  }

  const i18n = useI18n()
  app.use(i18n)

  const routes: RouteRecordRaw[] = _routes.reduce<RouteRecordRaw[]>((prev, current) => [...prev, ...i18n.global.availableLocales.reduce<RouteRecordRaw[]>((lp, lc) => [...lp, ...mapRoutesWithLocale(current, lc, store.state.session.country)], [])], [])

  let base = '/'

  const COM_DOMAINS = ['be'] as const

  if (COM_DOMAINS.includes(store.state.session.country)) {
    base = `/${store.state.session.country}/`
  }

  const router = createRouter({
    history: import.meta.env.SSR ? createMemoryHistory(base) : createWebHistory(base),
    routes,
    scrollBehavior(to, from, savedPosition) {
      const toMarketplace = (to.name as string)?.startsWith('marketplace')
      const fromEstablishment = (from.name as string)?.startsWith('establishment')

      if (toMarketplace && fromEstablishment && store.state.marketplace.currentPage > 1) {
        const {y} = store.state.marketplace.scrollPosition

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({left: 0, top: y})
          }, 10)
        })
      }

      if (to.name === from.name) {
        return
      }

      if (to.hash) {
        return {el: to.hash, behavior: 'smooth'}
      }

      if (savedPosition) {
        return savedPosition
      }

      return {top: 0}
    },
  })

  const locale = store.state.session.locale

  router.beforeEach(to => {
    if (to.meta.requiresAuth && !store.getters['auth/loggedIn']) {
      return {name: `login___${locale}`}
    }

    return true
  })

  createHttp({store, router, i18n})

  app.provide('platform', Capacitor.isNativePlatform() ? Capacitor.getPlatform() : 'web')

  const sentry = await createSentry({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_BUILD_ENV,
    router,
  })
  app.use(sentry)

  app.use(createGtm({
    id: import.meta.env.VITE_GOOGLE_GTM,
    defer: true,
    compatibility: false,
    enabled: true,
    debug: import.meta.env.DEV,
    loadScript: true,
    trackOnNextTick: false,
  }))

  if (!import.meta.env.SSR) {
    if (window && window.dataLayer) {
      // eslint-disable-next-line no-inner-declarations
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments)
      }

      // https://support.cookiebot.com/hc/en-us/articles/360016047000-Cookiebot-and-Google-Consent-Mode
      gtag('consent', 'default', {
        ad_personalization: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'granted',
        wait_for_update: 500,
      })
      gtag('set', 'ads_data_redaction', true)
      gtag('set', 'url_passthrough', true)
    }
  }

  const gtm = useGtm()
  app.use(plugins({sentry: sentry.instance, gtm, router}))

  if (__CAPACITOR__) {
    await capacitor(router, store)
  }

  i18n.global.locale.value = store.state.session.locale

  const {createLoader} = useGoogleMaps()
  createLoader(app)

  const driver = createTracking(store)

  if (!import.meta.env.SSR) {
    await driver.start()
  }

  return {
    router,
  }
})
