import type { App } from 'vue'
import taptic from './directives/taptic'
import bodyScrollLock from './directives/body_scroll_lock'
import draggable from './directives/draggable'
import { filters } from './globals/filters'

export function plugins() {
  return {
    async install(app: App) {
      app.directive('taptic', taptic)
      app.directive('body-scroll-lock', bodyScrollLock)
      app.directive('draggable', draggable)

      filters.forEach(([key, value]) => {
        app.config.globalProperties[key] = value
        app.provide(key, value)
      })

      // Vuex properties

      if (!import.meta.env.SSR) {
        // Fixes for Facebook native app browser
        window._AutofillCallbackHandler = window._AutofillCallbackHandler
          || function () {
          }
        window.instantSearchSDKJSBridgeClearHighlight = window.instantSearchSDKJSBridgeClearHighlight
          || function () {
          }
      }
    },
  }
}
