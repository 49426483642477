<script setup lang="ts">
import StaticMaps from "@/utils/google/staticMaps/generator"

const props = defineProps<{
  location: string
}>()

const url = StaticMaps.forLocation(props.location, 12)
</script>

<template>
  <div class="google-maps">
    <div class="google-maps__spacer">
      <img class="google-maps__map" :src="url" alt="map">
      <img class="google-maps__marker" src="/assets/img/icons/marker.svg" alt="marker">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.google-maps {
  width:          100%;
  height:         100%;
  overflow:       hidden;
  pointer-events: none;
  user-select:    none;
  position:       relative;

  &__spacer {
    width:  100%;
    height: 100%;
  }

  &__map {
    object-fit:      cover;
    object-position: center;
    position:        relative;
    overflow:        hidden;
    width:           100%;
    height:          100%;
  }

  &__marker {
    position:  absolute;
    top:       50%;
    left:      50%;
    overflow:  hidden;
    width:     2rem;
    height:    auto;
    transform: translate(-50%, -99%);
  }
}
</style>
