import type {TrackingDriver} from '@/tracking/contracts/TrackingDriver'
import {ApiTrackingDriver} from '@/tracking/drivers/ApiTrackingDriver'
import {useHttp} from '@/composables/useHttp'
import {MockTrackingDriver} from '@/tracking/drivers/MockTrackingDriver'

let driver: TrackingDriver | null = null

function createTracking(store): TrackingDriver {
  if (__CAPACITOR__) {
    driver = new MockTrackingDriver()
  } else {
    driver = new ApiTrackingDriver(useHttp(), store)
  }

  return driver
}

function useTracking(): TrackingDriver {
  if (null === driver) {
    throw new Error('Tracking driver not initialized')
  }

  return driver
}

export {useTracking, createTracking}