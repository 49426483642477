import axios, { type AxiosInstance, type InternalAxiosRequestConfig, isAxiosError } from 'axios'
import { version } from '../../package.json'
import type { Store } from 'vuex'
import type { I18n } from "vue-i18n"
import type { Router } from "vue-router"

let http = null

export const useHttp = (): AxiosInstance => {
  if (http === null) {
    throw new Error('HTTP Instance is not initialized')
  }

  return http
}

export const createHttp = ({ store, router, i18n }: { store: Store<any>, router: Router, i18n: I18n }): void => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: `${import.meta.env.VITE_BACKEND_URL}${import.meta.env.VITE_API_PATH}`,
  })

  instance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    request.headers.set('Access-Control-Allow-Origin', '*')
    request.headers.set('Accept', 'application/json')
    request.headers.set('Accept-Language', i18n.global.locale.value)
    request.headers.set('X-Country', store.state.session.country)
    request.headers.set('App-Version', version)

    if (store.getters['auth/loggedIn']) {
      request.headers.set('Authorization', `Bearer ${store.getters['auth/getToken'].access_token}`)
    }

    return request
  }, async (error): Promise<any> => {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    await store.dispatch('modal/show', {
      message: i18n.global.t('errors.connection-lost'),
      closable: false,
      data: error,
    })

    return Promise.reject(error)
  })

  instance.interceptors.response.use(null, async (error): Promise<any> => {
    if (!isAxiosError(error)) {
      return Promise.reject(error)
    }

    if (error.response?.status === 400) {
      if (error.response.data?.data?.code === 'UPGRADE_REQUIRED') {
        await store.dispatch('modal/show', {
          message: i18n.global.t('errors.update-required'),
          closable: false,
        })
      }
    }

    if (error.response?.status === 401) {
      await store.dispatch('user/setToken', null)

      await router.push({ name: `login___${i18n.global.locale.value}` })

      return
    }

    if (error.response?.status === 404) {
      if (import.meta.env.SSR) {
        return Promise.reject(error)
      }
    }

    if (error.response?.status >= 500) {
      await store.dispatch('modal/show', {
        message: i18n.global.t('errors.error-from-api'),
        closable: true,
        data: error,
      })
    }

    return Promise.reject(error)
  })

  http = instance
}